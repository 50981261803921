import React from "react"
import {isNullUndefined} from "../../../util/"
import {getDefaultUkResidentsHcpDisclaimerPopupConfigs} from "../../../util/configs"

export function toggleUkResidentsHcpDisclaimerPopup(e, urlToVisit, openPopup){
    e.preventDefault()
    if(isNullUndefined(urlToVisit)){
        return;
    }
    let okCallback = () => window.open(urlToVisit, "_blank")
    let closeCallback = () => {}
    let popupConfig = getDefaultUkResidentsHcpDisclaimerPopupConfigs(okCallback, closeCallback);

    openPopup(popupConfig)
}